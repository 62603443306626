<template>
  <div
    v-if="isVisible"
    class="modal-base"
    @click="$emit('close')"
  >
    <div
      class="modal"
      @click.stop
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalBase',
  props: {
    isVisible: Boolean
  }
}
</script>

<style scoped lang="scss">
.modal-base {
  display: flex;
  position: fixed;
  z-index: $z-index-backdrop;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $color-backdrop-bg;
  backdrop-filter: blur($blur-modal-backdrop);

  .modal {
    position: relative;
    width: $modal-width;
    padding: $spacing-32;
    border-radius: $border-radius-8;
    background: $color-blue-light;
    color: $color-white;

    @include mobile {
      max-height: 90%;
      overflow-y: auto;
    }
  }
}
</style>
