<template>
  <div class="hot-wallet">
    <PageTitle :title="$t('common.wallet')" />

    <PanelBase v-if="!kyc" expand>
      <SectionTitle :title="$t('wallet.verifyAccount')" />
      <p class="text">
        {{ $t('wallet.needToPassKyc') }}
      </p>
      <ButtonBase
        :expand="isMobile"
        :fit-content="!isMobile"
        medium-size
        variant="secondary"
        to="/account"
        >
        {{ $t('wallet.goVerify') }}
      </ButtonBase>
    </PanelBase>

    <template v-else>
      <PanelBase expand>
        <div class="token-wrapper">
          <SectionTitle :icon="GctIcon" :title="$t('common.gct')" />
          <div class="content-row">
            <p class="text">
              {{ withColon($t('wallet.available')) }}
            </p>
            <p class="value">
              {{ svsXrpl.toFixed(2) }} {{ $t('common.gct') }}
            </p>
          </div>
          <div class="content-row">
            <p class="text">
              {{ withColon($t('wallet.onHold')) }}
            </p>
            <p class="value">
              {{ svsXrplFrozen.toFixed(2) }} {{ $t('common.gct') }}
            </p>
          </div>
        </div>
        <div class="button-wrapper">
          <ButtonBase
            medium-size
            expand
            @click="toggleReceiveModal(!isReceiveModalVisible, 'GCT')"
          >
            {{ $t('wallet.receive', { name: $t('common.gct') }) }}
          </ButtonBase>
          <ButtonBase
            medium-size
            expand
            variant="secondary"
            to="/swap"
          >
            {{ $t('common.swap') }}
          </ButtonBase>
          <ButtonBase
            medium-size
            expand
            variant="outlined"
            @click="toggleSendModal(!isSendModalVisible, 'GCT')"
          >
            {{ $t('common.send') }}
          </ButtonBase>
        </div>
      </PanelBase>

      <PanelBase expand>
        <div class="token-wrapper">
          <SectionTitle :icon="XrpIcon" :title="$t('common.xrp')" />
          <div class="content-row">
            <p class="text">
              {{ withColon($t('wallet.available')) }}
            </p>
            <p class="value">
              {{ xrp }} {{ $t('common.xrp') }}
            </p>
          </div>
          <div class="content-row">
            <p class="text">
              {{ withColon($t('wallet.onHold')) }}
            </p>
            <p class="value">
              {{ xrpFrozen }} {{ $t('common.xrp') }}
            </p>
          </div>
        </div>
        <div class="button-wrapper">
          <ButtonBase
            medium-size
            expand
            @click="toggleReceiveModal(!isReceiveModalVisible, 'XRP')"
          >
            {{ $t('wallet.receive', { name: $t('common.xrp') }) }}
          </ButtonBase>
          <ButtonBase
            medium-size
            expand
            variant="secondary"
            to=/swap
          >
            {{ $t('common.swap') }}
          </ButtonBase>
          <ButtonBase
            medium-size
            expand
            variant="outlined"
            @click="toggleSendModal(!isSendModalVisible, 'XRP')"
          >
            {{ $t('common.send') }}
          </ButtonBase>
        </div>
      </PanelBase>

      <ModalBase
        :is-visible="isReceiveModalVisible"
        @close="toggleReceiveModal(!isReceiveModalVisible, '')"
      >
        <div class="modal-wrapper">
          <div class="content-wrapper">
            <h1 class="modal-title">
              {{ $t('wallet.receive', { name: receiveModalTitle }) }}
            </h1>
            <HorizontalRule />
          </div>

          <p v-if="isTransactionDisabled" class="text">
            {{ $t('wallet.receiveDisabled', { name: receiveModalTitle}) }}
          </p>

          <template v-else>
            <p class="text">
              {{ $t('wallet.receiveInfo') }}
            </p>

            <div class="modal-row">
              <h6 class="receive-text">
                {{ userTag }}
              </h6>
              <ButtonBase
                  variant="secondary"
                  @click="copyText(userTag)"
              >
                {{ $t('wallet.copyTag') }}
              </ButtonBase>
            </div>

            <div class="modal-row">
              <h6 class="receive-text">
                {{ xrpAddress }}
              </h6>
              <ButtonBase
                  variant="secondary"
                  @click="copyText(xrpAddress)"
              >
                {{ $t('wallet.copyAddress') }}
              </ButtonBase>
            </div>
          </template>

          <HorizontalRule />

          <div class="modal-button-wrapper">
            <ButtonBase
              medium-size
              variant="outlined"
              @click="toggleReceiveModal(!isReceiveModalVisible)"
            >
              {{ $t(isTransactionDisabled ? 'common.close' : 'common.cancel') }}
            </ButtonBase>
          </div>
        </div>
      </ModalBase>

      <ModalBase
        :is-visible="isSendModalVisible"
        @close="toggleSendModal(!isSendModalVisible, '')"
      >
        <div class="modal-wrapper">
          <div class="content-wrapper">
            <h1 class="modal-title">
              {{ $t('wallet.sendRequest') }}
            </h1>
            <HorizontalRule />
          </div>

          <p v-if="isTransactionDisabled" class="text">
            {{ $t('wallet.sendDisabled', { name: receiveModalTitle}) }}
          </p>

          <template v-else>
            <div class="content-wrapper">
              <h6 class="available-to-withdraw">
                {{ $t('wallet.availableToWithdraw', { value: sendModalInfo.availableValue }) }}
              </h6>

              <InputField
                v-model="sendModalInfo.address"
                expand
                hide-error
                :label="$t('wallet.sendToAddress')"
                :placeholder="$t('wallet.address')"
              />
              <InputField
                v-model="sendModalInfo.amount"
                expand
                hide-error
                type="number"
                :max="sendModalInfo.availableValue"
                :label="$t('wallet.withdrawAmount')"
                :placeholder="$t('common.amount')"
              />
              <InputField
                v-model="sendModalInfo.receiveUserTag"
                expand
                hide-error
                type="number"
                :label="$t('wallet.destinationTag')"
                :placeholder="$t('wallet.tagNumber')"
              />
              <InputField
                v-if="hasOTP"
                v-model="sendModalInfo.otp"
                expand
                hide-error
                type="number"
                :label="$t('auth.authCode')"
                :placeholder="$t('auth.code')"
              />
            </div>

            <div class="send-row">
              <p>
                {{ withColon($t('wallet.currentFee')) }}
                {{ sendModalInfo.feeValue }}
              </p>
              <p>
                {{ withColon($t('wallet.totalReceive')) }}
                {{ receiveValue }}
              </p>
            </div>
          </template>

          <HorizontalRule />

          <div class="modal-button-wrapper">
            <ButtonBase
              medium-size
              variant="outlined"
              @click="toggleSendModal(!isSendModalVisible)"
            >
              {{ $t(isTransactionDisabled ? 'common.close' : 'common.cancel') }}
            </ButtonBase>
            <ButtonBase
              medium-size
              @click="withdraw"
            >
              {{ $t('wallet.sendRequest') }}
            </ButtonBase>
          </div>

        </div>
      </ModalBase>
    </template>
  </div>
</template>

<script>
import callApi from '@/api'
import { useNotifications, useResize } from '@/composables'
import { STORAGE_KEY_USER_INFO } from '@/constants'
import { getFromLocalStorage, withColon } from '@/utils'
import ButtonBase from '@/components/ButtonBase.vue'
import HorizontalRule from '@/components/HorizontalRule.vue'
import InputField from '@/components/InputField.vue'
import ModalBase from '@/components/ModalBase.vue'
import PageTitle from '@/components/PageTitle.vue'
import PanelBase from '@/components/PanelBase.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import GctIcon from '@/assets/icons/gct-icon.svg'
import XrpIcon from '@/assets/icons/xrp-icon.svg'

export default {
  name: 'HotWallet',
  components: {
    InputField,
    HorizontalRule,
    ModalBase,
    ButtonBase,
    PageTitle,
    PanelBase,
    SectionTitle
  },
  computed: {
    receiveValue () {
      if (this.sendModalInfo.amount) {
        const receivedValue = parseFloat(this.sendModalInfo.amount) - this.sendModalInfo.feeValue
        return receivedValue > 0 ? receivedValue : 0
      } else {
        return 0
      }
    }
  },
  data () {
    return {
      isReceiveModalVisible: false,
      isSendModalVisible: false,
      receiveModalTitle: '',
      sendModalInfo: {
        name: '',
        availableValue: 0,
        feeValue: 0,
        address: '',
        amount: 0,
        receiveUserTag: 0,
        otp: ''
      },
      address: '',
      isFormLoading: false,
      isLoading: false,
      isTransactionDisabled: false,
      kyc: false,
      svsXrpl: 0,
      svsXrplFee: 0,
      svsXrplFrozen: 0,
      xrp: 0,
      xrpFee: 0,
      xrpFrozen: 0,
      hasOTP: false,
      xrpAddress: '',
      userTag: 0,
      GctIcon,
      XrpIcon,
      withColon
    }
  },
  methods: {
    async copyText (content) {
      await navigator.clipboard.writeText(content)
      this.pushNotification({
        titleKey: 'notifications.success.copied',
        type: 'success'
      })
    },
    async fetchData () {
      this.isLoading = true
      const { success, result } = await callApi.post('/api/positionquery', { wallet: 'main' }, {}, true)
      if (success) {
        this.svsXrpl = result.svs_xrpl
        this.svsXrplFee = result.svs_xrpl_fee
        this.svsXrplFrozen = result.svs_xrpl_frozen
        this.xrpAddress = result.xrp_address
        this.xrp = result.xrp
        this.xrpFee = result.xrp_fee
        this.xrpFrozen = result.xrp_frozen
        this.isTransactionDisabled = result.flag
        this.hasOTP = result.otp
        this.userTag = result.tag
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.walletInfoObtain',
          type: 'error'
        })
      }
    },
    toggleReceiveModal (value, name) {
      this.isReceiveModalVisible = value
      this.receiveModalTitle = name
    },
    toggleSendModal (value, name) {
      this.isSendModalVisible = value
      this.sendModalInfo = name ? {
        name: name,
        availableValue: name === 'GCT' ? this.svsXrpl : this.xrp,
        feeValue: name === 'GCT' ? this.svsXrplFee : this.xrpFee
      } : {
        name: '',
        availableValue: 0,
        feeValue: 0,
        address: '',
        amount: '',
        userTag: '',
        otp: ''
      }
    },
    async withdraw () {
      const {
        name,
        address: sendTo,
        amount: value,
        otp,
        receiveUserTag: memo
      } = this.sendModalInfo
      const data = {
        type: name === 'GTC' ? 'svs_xrpl' : 'xrp',
        sendTo,
        value,
        otp,
        memo
      }
      const { success } = await callApi.post('/api/xrpl-withdraw', data) // TODO: fix and polish when BE will be ready
      if (success) {
        this.pushNotification({
          titleKey: 'notifications.success.withdrawRequestSent',
          type: 'success'
        })
        await this.fetchData()
        this.toggleSendModal(!this.isSendModalVisible, '')
      }
    }
  },
  async mounted () {
    await this.fetchData()
    const data = getFromLocalStorage(STORAGE_KEY_USER_INFO, true)
    if (data) {
      this.address = data.address
      this.kyc = data.kyc
    }
  },
  setup () {
    const { pushNotification } = useNotifications()
    const { isMobile } = useResize()
    return { isMobile, pushNotification }
  }
}

</script>

<style scoped lang="scss">
.hot-wallet {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  padding: $spacing-32 $spacing-24;
  width: 60%;

  @include mobile {
    padding: $spacing-16 $spacing-20;
    width: 100%;
  }

  .text {
    @extend %font-medium-rg;
  }

  .receive-text {
    @extend %font-sb-lg;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .value {
    @extend %font-sb-2xl;
  }

  .token-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-12;
  }

  .content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    gap: $spacing-24;

    @include mobile {
      flex-direction: column;
      justify-content: unset;
      gap: $spacing-16;
    }
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-32;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: $spacing-24;
    }

    .modal-title {
      @extend %font-sb-2xl;
    }

    .modal-row {
      display: flex;
      align-items: center;
      gap: $spacing-24;

      .button {
        white-space: nowrap;
      }
    }

    .modal-button-wrapper {
      display: flex;
      justify-content: end;
      gap: $spacing-16;

      @include mobile {
        flex-direction: column-reverse;
      }
    }

    .available-to-withdraw {
      @extend %font-sb-xl;
    }

    .send-row {
      @extend %font-sb-rg;

      display: flex;
      gap: $spacing-32;
      color: $color-primary-05;
    }
  }
}

</style>
