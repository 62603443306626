<template>
  <div class="forget-password">
    <PanelBase align-center>
      <img class="logo" :src="GctLogoImg" alt="logo" />
      <h6 class="title">
        {{  $t('auth.resetPassword') }}
      </h6>
      <form class="form">
        <InputField
          :model-value="email"
          expand
          hide-error
          :label="$t('auth.email')"
          :placeholder="$t('auth.email')"
          @input="(value) => $set($data, 'email', value)"
        />
        <ButtonBase
          expand
          medium-size
          type="submit"
          :loading="isFormLoading"
          @click="handleSubmit"
        >
          {{ $t('auth.sendResetPasswordEmail') }}
        </ButtonBase>
      </form>
      <div class="login-wrapper">
        {{ $t('auth.backTo') }}
        <RouterLink class="link" to="/">
          {{ $t('auth.login') }}
        </RouterLink>
      </div>
    </PanelBase>
  </div>
</template>

<script>
import callApi from '@/api'
import { useNotifications } from '@/composables'
import ButtonBase from '@/components/ButtonBase.vue'
import InputField from '@/components/InputField.vue'
import PanelBase from '@/components/PanelBase.vue'
import GctLogoImg from '@/assets/gct-logo.png'

export default {
  name: 'login',
  data () {
  	return {
  		email: '',
      isFormLoading: false,
      GctLogoImg
  	}
  },
  components: {
    ButtonBase,
    InputField,
    PanelBase
  },
  methods: {
  	async handleSubmit (event) {
      event.preventDefault()
      this.isFormLoading = true
      const { success } = await callApi.post('/api/resetpassword', { email: this.email })
  		if (success) {
        this.pushNotification({
          titleKey: 'notifications.success.resetEmailSent',
          type: 'success'
        })
        this.$set(this.$data, 'email', '')
      }
      this.isFormLoading = false
  	}
  },
  setup () {
    const { pushNotification } = useNotifications()
    return { pushNotification }
  }
}
</script>

<style scoped lang="scss">
.forget-password {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  .title {
    @extend %font-medium-sm;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    width: 100%;
  }

  .link {
    @extend %font-sb-sm;

    color: $color-primary-05;
    cursor: pointer;
  }

  .login-wrapper {
    @extend %font-medium-sm;

    display: flex;
    gap: $spacing-4;
  }
}
</style>
