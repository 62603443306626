<template>
  <div class="new-stake">

    <PageTitle :title="$t('common.earn')" />

    <PanelBase expand>
      <SectionTitle :title="$t('earn.chooseLockInPeriod')" />

      <div class="question-wrapper">
        <h6 class="text-regular">
          {{ $t('earn.howItWorks') }}
        </h6>

        <BulletPoint
          v-for="key in bulletPointLabelKeys"
          :key="key"
          :text="$t(`earn.${key}`)"
        />
      </div>
    </PanelBase>

    <PanelBase
      v-if="isLoading || isTransactionDisabled"
      expand
    >
      <SpinnerLoader
        v-if="isLoading"
        is-big
      />
      <p
        v-else
        class="text"
      >
        {{ $t('swap.swapDisabled') }}
      </p>
    </PanelBase>

    <template v-else>
      <PanelBase
        v-for="(period, index) in LOCK_IN_TERMS"
        :key="index"
        expand
      >
        <SectionTitle :title="$t('earn.lockFor', { value: period })" />

        <div class="lock-in-info-wrapper">
          <PanelBase
            variant="blue"
            :dense="!isMobile"
            :expand="isMobile"
          >
            <h6 class="text-regular">
              {{ $t('earn.yield')}}
            </h6>
            <div class="value-wrapper">
              <h4 class="value-text">
                {{ withPercent(yieldValue[index].toFixed(2)) }}
              </h4>
            </div>
          </PanelBase>

          <PanelBase
            variant="blue"
            :dense="!isMobile"
            :expand="isMobile"
          >
            <h6 class="text-regular">
              {{ $t('earn.gctLocked')}}
            </h6>
            <div class="value-wrapper">
              <h4 class="value-text">
                ${{ (svsPrice * svsPool[index]).toFixed(2) }}
              </h4>
              <h6 class="value-content">
                {{ svsPool[index] }}
                {{ $t('common.gct') }}
              </h6>
            </div>
          </PanelBase>

          <PanelBase
            variant="blue"
            :dense="!isMobile"
            :expand="isMobile"
          >
            <h6 class="text-regular">
              {{ $t('earn.gctRewards')}}
            </h6>
            <div class="value-wrapper">
              <h4 class="value-text">
                ${{ (svsPrice * svsReward[index]).toFixed(2) }}
              </h4>
              <h6 class="value-content">
                {{ svsReward[index].toFixed(4) }}
                {{ $t('common.gct')}}
              </h6>
            </div>
          </PanelBase>
        </div>

        <p>
          {{ withColon($t('earn.depositFee')) }}
          {{ withPercent(basketDepositFee[index] * 100) }}
          {{ $t('earn.onPrinciple') }}
        </p>

        <p>
          {{ withColon($t('earn.charityDonation')) }}
          {{ withPercent(basketPerformanceFee[index] * 100) }}
          {{ $t('earn.onProfits') }}
        </p>

        <div
          v-if="!hasEnded"
          class="input-wrapper"
        >
          <InputField
            hide-error
            expand
            :model-value="amount[index]"
            :disabled="!svsAvailable"
            :label="$t('common.amount')"
            :placeholder="$t('common.enterValue')"
            type="number"
            @input="(value) => handleInput(value, index)"
          />

          <CheckboxBase
            v-if="svsAvailable"
            :model-value="isFromAmountEqualMaxValueOfWallet[index]"
            :label="$t('swap.maxValueOfWallet')"
            @input="(value) => handleCheckboxClick(value, index)"
            hide-error
          />

          <p class="balance-text">
            {{ withColon($t('common.yourBalance')) }}
            {{ svsAvailable.toFixed(4)}}
          </p>
        </div>

        <div class="date-wrapper">
          {{ withColon($t('earn.startDate')) }}
          {{ startDate.slice(0, 10) }}
        </div>

        <div class="date-wrapper">
          {{ withColon($t('earn.unlockDate')) }}
          {{ endDate[index].slice(0, 10) }}
        </div>

        <HorizontalRule v-if="!hasEnded"/>

        <ButtonBase
          v-if="!hasEnded"
          variant="secondary"
          medium-size
          expand
          @click="lockTokens(index)"
        >
          {{ $t('earn.lockIn') }}
        </ButtonBase>
      </PanelBase>

      <PageTitle
        :title="$t('earn.activeLocksIn')"
        class="active-locks-title"
      />

      <EmptyComponent
        v-if="!originalHistories.length"
        :title="$t('earn.empty')"
      />

      <div
          v-else
          class="content-wrapper"
      >
        <InputField
            v-model="searchValue"
            @input="handleSearch"
            class="search-input"
            expand
            hide-error
            :placeholder="$t('common.search')"
        >
          <template #icon-left>
            <IconComponent
                :icon="SearchIcon"
                size="sm"
            />
          </template>
        </InputField>

        <EmptyComponent
            v-if="!histories.length"
            :title="$t('common.noResults')"
        />

        <TableComponent
            v-else
            :data="histories"
            :header-config="headerConfig"
            :horizontal-scroll="isMobile"
            :sort="sort"
            @sort="(sortValue) => handleSort(sortValue)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import callApi from '@/api'
import { useNotifications, useResize } from '@/composables'
import { COINGECKO_PRICE_URL, LOCK_IN_TERMS } from '@/constants'
import { withColon, withPercent } from '@/utils'
import BulletPoint from '@/components/BulletPoint.vue'
import ButtonBase from '@/components/ButtonBase.vue'
import CheckboxBase from '@/components/CheckboxBase.vue'
import EmptyComponent from '@/components/EmptyComponent.vue'
import HorizontalRule from '@/components/HorizontalRule.vue'
import IconComponent from '@/components/IconComponent.vue'
import InputField from '@/components/InputField.vue'
import PageTitle from '@/components/PageTitle.vue'
import PanelBase from '@/components/PanelBase.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import TableComponent from '@/components/table/TableComponent.vue'
import SearchIcon from '@/assets/icons/search-icon.svg'

export default {
  name: 'Staking',
  components: {
    BulletPoint,
    ButtonBase,
    CheckboxBase,
    EmptyComponent,
    HorizontalRule,
    IconComponent,
    InputField,
    PageTitle,
    PanelBase,
    SectionTitle,
    SpinnerLoader,
    TableComponent
  },
  data () {
    return {
      amount: [null, null, null],
      basketDepositFee: [],
      basketPerformanceFee: [],
      bulletPointLabelKeys: ['lockInAvailable', 'lockInMeans', 'rewardsDistributed'],
      endDate: [],
      hasEnded: true,
      isFromAmountEqualMaxValueOfWallet: [false, false, false],
      isLoading: true,
      isTransactionDisabled: false,
      rewardTotal: 0,
      stakedTotal: 0,
      startDate: null,
      svsAvailable: 0,
      svsPool: [],
      svsReward: [],
      svsPrice: 0,
      yieldValue: [],
      headerConfig: {
        pool_name: {
          labelKey: 'earn.table.pool',
          sortable: true
        },
        amount: {
          labelKey: 'earn.table.amount',
          sortable: true
        },
        commerce_at: {
          labelKey: 'earn.table.commerceAt',
          sortable: true
        },
        release_at: {
          labelKey: 'earn.table.releaseAt',
          sortable: true
        }
      },
      histories: [],
      originalHistories: [],
      searchValue: '',
      sort: undefined,
      LOCK_IN_TERMS,
      withPercent,
      SearchIcon
    }
  },
  async created () {
    await this.fetchData()
    await this.getSVSPrice()
  },
  methods: {
    withColon,
    async getSVSPrice () {
      this.isLoading = true
      const { success, result } = await callApi.get(COINGECKO_PRICE_URL, {}, true)
      if (success) {
        this.price = result['givingtoservices-svs'].usd
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.tokenPriceObtain',
          type: 'error'
        })
      }
    },
    async fetchData () {
      this.isLoading = true
      const { success, result } = await callApi.get('/api/new-staking-info')
      if (success) {
        this.basketDepositFee = [result.basket4depositfee, result.basket5depositfee, result.basket6depositfee]
        this.basketPerformanceFee = [result.basket4performancefee, result.basket5performancefee, result.basket6performancefee]
        this.endDate = [result.end4, result.end5, result.end6]
        this.hasEnded = result.hasEnded
        this.originalHistories = result.histories
        this.histories = result.histories
        this.isTransactionDisabled = result.flag
        this.rewardTotal = result.reward_total
        this.stakedTotal = result.staked_total
        this.startDate = result.startdate
        this.svsAvailable = result.svs_avail
        this.svsPool = [result.svs_pool4, result.svs_pool5, result.svs_pool6]
        this.svsReward = [result.svs_reward4, result.svs_reward5, result.svs_reward6]
        this.yieldValue = [result.apr4, result.apr5, result.apr6]
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.newStakingInfoObtain',
          type: 'error'
        })
      }
    },
    handleCheckboxClick (value, index) {
      this.$set(this.isFromAmountEqualMaxValueOfWallet, index, value)
      if (value) {
        this.$set(this.amount, index, this.svsAvailable)
      }
    },
    handleInput (value, index) {
      this.$set(this.amount, index, value)
      this.$set(
        this.isFromAmountEqualMaxValueOfWallet,
        index,
        this.amount[index] === this.svsAvailable
      )
    },
    handleSearch (value) {
      if (!value) {
        this.histories = this.originalHistories
        return
      }
      this.histories = this.originalHistories.filter(obj =>
        Object.values(obj).some(value =>
          value.toString().toLowerCase().includes(this.searchValue.toLowerCase())
        )
      )
    },
    handleSort (value) {
      this.sort = value
      this.histories = orderBy(this.histories, this.sort.field, this.sort.order.toLowerCase())
    },
    async lockTokens (index) {
      const data = {
        pool: index + 4, // BE give values of 4, 5, 6, dont know why
        value: this.amount[index],
        price: this.svsPrice
      }
      const { success } = await callApi.post('/api/new-transfer', data)
      if (success) {
        this.pushNotification({
          titleKey: 'notifications.success.newStakeLockIn',
          type: 'success'
        })
        this.$set(this.amount, index, null)
        await this.fetchData()
      }
    }
  },
  setup () {
    const { pushNotification } = useNotifications()
    const { isMobile } = useResize()
    return { isMobile, pushNotification }
  }
}
</script>

<style scoped lang="scss">
.new-stake {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  padding: $spacing-32 $spacing-24;
  width: 60%;
  height: fit-content;

  @include mobile {
    padding: $spacing-16 $spacing-20;
    width: 100%;
  }

  .question-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }

  .lock-in-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-16;

    @include mobile {
      flex-direction: column;
    }

    .value-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  .date-wrapper {
    display: flex;
    gap: $spacing-40;
  }

  .active-locks-title {
    margin-top: $spacing-8;
  }

  .search-input {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }

  .text-regular {
    @extend %font-sb-rg;
  }

  .value-text {
    @extend %font-sb-2xl;

    font-weight: 800;
  }

  .value-content {
    @extend %font-medium-sm;
  }

  .text {
    @extend %font-medium-rg;
  }

  .balance-text {
    @extend %font-medium-rg;

    color: $color-primary-05;
  }
}
</style>
