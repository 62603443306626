<template>
  <div class="login">
    <PanelBase align-center>
      <img class="logo" :src="GctLogoImg" alt="logo" />
      <h6 class="title">
        {{ $t('auth.welcomeInGct') }}
      </h6>
      <form class="form">
        <InputField
          expand
          hide-error
          v-model="data.username"
          :label="$t('auth.username')"
          :placeholder="$t('auth.username')"
        />
        <InputField
          expand
          hide-error
          v-model="data.password"
          :label="$t('auth.password')"
          :placeholder="$t('auth.password')"
          type="password"
        />
        <CheckboxBase
          hide-error
          :label="$t('auth.fscs')"
          :model-value="status"
          @input="handleStatus"
        />
        <ExpandableWrapper :is-expanded="data.otpRequired">
          <InputField
            expand
            hide-error
            type="number"
            v-model="data.otp"
            :label="$t('auth.authCode')"
            :placeholder="$t('auth.code')"
          />
        </ExpandableWrapper>
        <ButtonBase
            expand
            medium-size
            :loading="isFormLoading"
            type="submit"
            @click="handleSubmit"
        >
          {{ $t('auth.logIn') }}
        </ButtonBase>
      </form>
      <RouterLink class="link" to="/resetpassword">
        {{ $t('auth.forgotPassword') }}
      </RouterLink>
      <div class="register-wrapper">
        {{ $t('auth.dontHaveAccount') }}
        <RouterLink class="link" to="/signup">
          {{$t('auth.signUp')}}
        </RouterLink>
      </div>
    </PanelBase>
	</div>
</template>

<script>
import callApi from '@/api'
import { useNotifications } from '@/composables'
import ButtonBase from '@/components/ButtonBase.vue'
import CheckboxBase from '@/components/CheckboxBase.vue'
import ExpandableWrapper from '@/components/ExpandableWrapper.vue'
import InputField from '@/components/InputField.vue'
import PanelBase from '@/components/PanelBase.vue'
import GctLogoImg from '@/assets/gct-logo.png'

export default {
  name: 'login',
  data () {
  	return {
  		data: {
  			username: '',
  			password: '',
        otpRequired: false,
        otp: ''
  		},
      isFormLoading: false,
      status: false,
      GctLogoImg
  	}
  },
  components: {
    ButtonBase,
    CheckboxBase,
    ExpandableWrapper,
    InputField,
    PanelBase
  },
  methods: {
    handleStatus (value) {
      this.status = value
    },
  	async handleSubmit (event) {
  		event.preventDefault()
      this.isFormLoading = true
      if (!this.status) {
        this.pushNotification({
          titleKey: 'notifications.error.loginFscs',
          type: 'error'
        })
        this.isFormLoading = false
        return
      }
      const { success, result } = await callApi.post('/api/login', this.data)
      if (success) {
        const { status } = result
        if (status === true) {
          window.location.href = '/dashboard'
        } else if (status === 'otpRequired') {
          this.data.otpRequired = true
          this.pushNotification({
            titleKey: 'notifications.success.giveAuthCode',
            type: 'info'
          })
        }
      }
      this.isFormLoading = false
  	}
  },
  setup () {
    const { pushNotification } = useNotifications()
    return { pushNotification }
  }
}
</script>

<style scoped lang="scss">
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  .title {
    @extend %font-medium-sm;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
  }

  .link {
    @extend %font-sb-sm;

    color: $color-primary-05;
    cursor: pointer;
  }

  .register-wrapper {
    @extend %font-medium-sm;

    display: flex;
    gap: $spacing-4;
  }
}
</style>
