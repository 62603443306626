<template>
  <div class="topbar">
    <img
      v-if="isMobile"
      class="logo"
      :src="GctLogoImg"
      alt="logo"
    />
    <div class="icon-wrapper">
      <ButtonBase
        v-for="(social, index) in socialConfig"
        :key="index"
        variant="icon"
        :href="social.link"
      >
        <Component
          :is="social.icon"
          class="icon-svg"
        />
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { useResize } from '@/composables'
import {
  INSTAGRAM_URL,
  TELEGRAM_URL,
  X_URL
} from '@/constants'
import ButtonBase from './ButtonBase.vue'
import GctLogoImg from '@/assets/gct-logo.png'
import InstagramIcon from '@/assets/icons/instagram-icon.svg'
import TelegramIcon from '@/assets/icons/telegram-icon.svg'
import XIcon from '@/assets/icons/x-icon.svg'
export default {
  components: {
    ButtonBase,
    InstagramIcon,
    TelegramIcon,
    XIcon
  },
  data () {
    return {
      GctLogoImg
    }
  },
  setup () {
    const { isMobile } = useResize()
    const socialConfig = [
      {
        icon: TelegramIcon,
        link: TELEGRAM_URL
      },
      {
        icon: XIcon,
        link: X_URL
      },
      {
        icon: InstagramIcon,
        link: INSTAGRAM_URL
      }
    ]
    return { isMobile, socialConfig }
  }
}
</script>

<style scoped lang="scss">
.topbar {
  @include generate-shadow($topbar-shadow);

  height: $topbar-height;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: $spacing-24 $spacing-32;
  background: $color-blue-light;
  color: $color-white;

  @include mobile {
    justify-content: space-between;
    padding: $spacing-20 $spacing-16;
  }

  .logo {
    height: $topbar-logo-height;
  }

  .icon-wrapper {
    display: flex;
    gap: $spacing-24;

    @include mobile {
      gap: $spacing-8;
    }

    .icon-svg {
      width: 24px;
    }
  }
}
</style>
