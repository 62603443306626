<template>
  <div class="swap">
    <PageTitle :title="$t('common.swap')" />

    <PanelBase expand>
      <div class="exchange-wrapper">
        <SectionTitle
          :title="$t('swap.exchange')"
          :icon="LampIcon"
        />
        <p class="text">
          {{ $t('swap.swapEasily') }}
        </p>
      </div>
      <ButtonBase
        variant="outlined"
        to="/wallet"
        medium-size
        expand
      >
        {{ $t('swap.goToWallet') }}
      </ButtonBase>
    </PanelBase>

    <PanelBase
      v-if="isLoading || isTransactionDisabled"
      expand
    >
      <SpinnerLoader
        v-if="isLoading"
        is-big
      />
      <p
        v-else
        class="text"
      >
        {{ $t('swap.swapDisabled') }}
      </p>
    </PanelBase>

    <template v-else>
      <PanelBase expand>
        <div class="swap-wrapper">
          <h6 class="title">
            {{ $t('swap.step1') }}
          </h6>
        </div>

        <div class="exchange-wrapper">
          <div class="input-wrapper">
            <InputField
                hide-error
                fit-content
                :model-value="fromAmount"
                :label="$t('common.amount')"
                :placeholder="$t('common.enterValue')"
                type="number"
                @input="handleInputFromAmount"
            />
            <SelectInput
                :model-value="fromCurrencyToken"
                hide-error
                fit-content
                :label="$t('swap.currency')"
                :placeholder="$t('common.xrp')"
                :options="fromCurrencyOptions"
            />
          </div>
          <CheckboxBase
              v-if="xrp"
              :model-value="isFromAmountEqualMaxValueOfWallet"
              :label="$t('swap.maxValueOfWallet')"
              @input="handleCheckboxClick"
          />
          <p class="balance-text">
            {{ withColon($t('common.yourBalance')) }}
            {{ xrp.toFixed(4)}}
          </p>
        </div>

        <div class="separator">
          <HorizontalRule />
          <div class="icon-wrapper">
            <IconComponent :icon="RefreshIcon" size="rg" />
          </div>
          <HorizontalRule />
        </div>

        <div class="swap-wrapper">
          <h6 class="title">
            {{ $t('swap.step2') }}
          </h6>
        </div>

        <div class="exchange-wrapper">
          <div class="input-wrapper">
            <div class="amount-wrapper">
              <p class="amount-text">
                {{ $t('common.amount') }}
              </p>
              <TextEllipsis class="amount-value">
                {{ receiveAmount.toFixed(4) }}
              </TextEllipsis>
            </div>
            <SelectInput
                :model-value="receiveCurrencyToken"
                hide-error
                fit-content
                :label="$t('swap.currency')"
                :placeholder="$t('common.gct')"
                :options="receiveCurrencyOptions"
            />
          </div>
          <p class="balance-text">
            {{ withColon($t('common.yourBalance')) }}
            {{ svsXrpl.toFixed(4)}}
          </p>
        </div>

        <ButtonBase
            :loading="isFormLoading"
            :disabled="!xrp"
            expand
            medium-size
            @click="swap"
        >
          {{ $t('common.swap') }}
        </ButtonBase>
      </PanelBase>

      <PanelBase
          expand
          class="price-info"
      >
        <p class="price-info-text">
          {{ withColon($t('swap.price')) }}
          <span class="price-info-value">
          {{ price.toFixed(4) }}
        </span>
        </p>
        <p class="price-info-text">
          {{ withColon($t('swap.maxSlipage')) }}
          <span class="price-info-value">
          {{ withPercent((swapSlipage * 100).toFixed(2)) }}
        </span>
        </p>
        <p class="price-info-text">
          {{ withColon($t('swap.minSwap')) }}
          <span class="price-info-value">
          {{ $t('swap.worthOfXRP', {value: `$${minValueOfSwap}`}) }}
        </span>
        </p>
        <p class="price-info-text">
          {{ withColon($t('swap.swapFee')) }}
          <span class="price-info-value">
          {{ withPercent((swapFee * 100).toFixed(2)) }}
        </span>
        </p>
      </PanelBase>
    </template>
  </div>
</template>

<script>
import callApi from '@/api'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import { useNotifications } from '@/composables'
import { COINGECKO_PRICE_URL } from '@/constants'
import { withColon, withPercent } from '@/utils'
import ButtonBase from '@/components/ButtonBase.vue'
import CheckboxBase from '@/components/CheckboxBase.vue'
import HorizontalRule from '@/components/HorizontalRule.vue'
import IconComponent from '@/components/IconComponent.vue'
import InputField from '@/components/InputField.vue'
import PageTitle from '@/components/PageTitle.vue'
import PanelBase from '@/components/PanelBase.vue'
import SectionTitle from '@/components/SectionTitle.vue'
import SelectInput from '@/components/SelectInput.vue'
import TextEllipsis from '@/components/TextEllipsis.vue'
import LampIcon from '@/assets/icons/lamp-icon.svg'
import RefreshIcon from '@/assets/icons/refresh-icon.svg'

export default {
  name: 'Swap',
  components: {
    SpinnerLoader,
    ButtonBase,
    CheckboxBase,
    HorizontalRule,
    IconComponent,
    InputField,
    PageTitle,
    PanelBase,
    SectionTitle,
    SelectInput,
    TextEllipsis
  },
  data () {
    return {
      fromAmount: null,
      fromCurrencyOptions: [{
        value: 'XRP',
        label: this.$t('common.xrp')
      }],
      fromCurrencyToken: 'XRP',
      receiveCurrencyOptions: [{
        value: 'SVS',
        label: this.$t('common.gct')
      }],
      receiveCurrencyToken: 'SVS',
      isFormLoading: false,
      isFromAmountEqualMaxValueOfWallet: false,
      isLoading: false,
      isTransactionDisabled: false,
      minValueOfSwap: 100,
      svsXrpl: 0,
      svsXrplPrice: 0,
      xrp: 0,
      xrpPrice: 0,
      swapFee: 0,
      swapSlipage: 0,
      swapSpread: 0,
      withColon,
      withPercent,
      LampIcon,
      RefreshIcon
    }
  },
  computed: {
    price () {
      return (this.xrpPrice / this.svsXrplPrice) * (1 - this.swapSpread)
    },
    receiveAmount () {
      return this.fromAmount ? this.fromAmount * this.price : 0
    },
    usdVal () {
      return this.fromAmount * this.xrpPrice
    }
  },
  async created () {
    await this.fetchData()
    await this.getPrices()
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      const { success, result } = await callApi.post('/api/swap-info', { wallet: 'main' }, {}, true)
      if (success) {
        this.svsXrpl = result.svs_xrpl
        this.xrp = result.xrp
        this.swapFee = result.swapfee
        this.swapSlipage = result.swapslipage
        this.swapSpread = result.swapspread
        this.isTransactionDisabled = result.flag
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.swapInfoObtain',
          type: 'error'
        })
      }
    },
    async getPrices () {
      this.isLoading = true
      const { success, result } = await callApi.get(COINGECKO_PRICE_URL, {}, true)
      if (success) {
        this.svsXrplPrice = result['givingtoservices-svs'].usd
        this.xrpPrice = result.ripple.usd
        this.isLoading = false
      } else {
        this.pushNotification({
          titleKey: 'notifications.error.tokenPriceObtain',
          type: 'error'
        })
      }
    },
    async swap () {
      this.isFormLoading = true
      if (this.usdVal < this.minValueOfSwap) {
        this.pushNotification({
          titleKey: 'notifications.error.swapAmountExceeds',
          titleProperties: { value: this.minValueOfSwap },
          type: 'error'
        })
        this.isFormLoading = false
        return
      }
      const data = {
        fromToken: this.fromCurrencyToken,
        toToken: this.receiveCurrencyToken,
        fromAmount: this.fromAmount,
        toAmount: this.receiveAmount
      }
      const { success } = await callApi.post('/api/swap', data)
      if (success) {
        this.pushNotification({
          titleKey: 'notifications.success.swap',
          type: 'success'
        })
        this.fromAmount = null
        await this.fetchData()
      }
      this.isFormLoading = false
    },
    handleCheckboxClick (value) {
      this.isFromAmountEqualMaxValueOfWallet = value
      if (value) {
        this.fromAmount = this.xrp
      }
    },
    handleInputFromAmount (value) {
      this.$set(this.$data, 'fromAmount', value)
      this.isFromAmountEqualMaxValueOfWallet = (this.fromAmount === this.xrp)
    }
  },
  setup () {
    const { pushNotification } = useNotifications()
    return { pushNotification }
  }
}
</script>

<style scoped lang="scss">
.swap {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;
  padding: $spacing-32 $spacing-24;
  width: 60%;
  height: fit-content;

  @include mobile {
    padding: $spacing-16 $spacing-20;
    width: 100%;
  }

  .exchange-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }

  .swap-wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-32;
  }

  .input-wrapper {
    display: flex;
    gap: $spacing-16;
  }

  .balance-text {
    @extend %font-medium-rg;

    color: $color-primary-05;
  }

  .text {
    @extend %font-medium-rg;
  }

  .title {
    @extend %font-sb-rg;
  }

  .separator {
    display: flex;
    align-items: center;
    gap: $spacing-24;

    .horizontal-rule {
      width: 100%;
    }

    .icon-wrapper {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: $section-title-icon-wrapper-size;
      height: $section-title-icon-wrapper-size;
      background-image: url('../assets/icons/icon-wrapper.svg');
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }
  }

  .amount {
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: $spacing-8;
    }

    &-text {
      @extend %font-medium-sm;
    }

    &-value {
      @extend %font-sb-3xl;

      max-width: $swap-amount-value-max-width;
    }
  }

  .price-info {
    &-text {
      @extend %font-medium-rg;
    }
  }
}
</style>
