<template>
  <div class="signup">
    <PanelBase align-center>
      <img class="logo" :src="GctLogoImg" alt="logo" />
      <h6 class="title">
        {{ $t('auth.register') }}
      </h6>
      <form class="form">
        <InputField
          expand
          hide-error
          v-model="data.username"
          :label="$t('auth.username')"
          :placeholder="$t('auth.username')"
        />
        <InputField
          expand
          hide-error
          v-model="data.email"
          :label="$t('auth.email')"
          :placeholder="$t('auth.email')"
        />
        <InputField
          expand
          hide-error
          v-model="data.password"
          :label="$t('auth.password')"
          :placeholder="$t('auth.password')"
          type="password"
        />
        <InputField
          expand
          hide-error
          v-model="data.confirmPassword"
          :label="$t('auth.repeatPassword')"
          :placeholder="$t('auth.repeatPassword')"
          type="password"
        />
        <ButtonBase
          expand
          medium-size
          :loading="isFormLoading"
          @click="handleSubmit"
        >
          {{ $t('auth.signUp') }}
        </ButtonBase>
        <div class="login-wrapper">
          {{ $t('auth.alreadyHaveAccount') }}
          <RouterLink class="link" to="/">
            {{ $t('auth.signIn') }}
          </RouterLink>
        </div>
      </form>
    </PanelBase>
  </div>
</template>

<script>
import callApi from '@/api'
import { useNotifications } from '@/composables'
import ButtonBase from '@/components/ButtonBase.vue'
import InputField from '@/components/InputField.vue'
import PanelBase from '@/components/PanelBase.vue'
import GctLogoImg from '@/assets/gct-logo.png'

export default {
  name: 'signup',
  components: {
    ButtonBase,
    InputField,
    PanelBase
  },
  data () {
  	return {
  		data: {
  			username: '',
  			email: '',
  			password: '',
  			confirmPassword: ''
  		},
      isFormLoading: false,
      GctLogoImg
  	}
  },
  methods: {
  	async handleSubmit (event) {
      event.preventDefault()
      this.isFormLoading = true
      if (this.data.password !== this.data.confirmPassword) {
        this.pushNotification({
          titleKey: 'notifications.error.passwordArentSame',
          type: 'error'
        })
        this.isFormLoading = false
        return
      }
  		const { success, result } = await callApi.post('/api/signup', this.data)
      if (success) {
        const { status } = result
        if (status) {
          this.pushNotification({
            titleKey: 'notifications.success.signedUp',
            type: 'success'
          })
          await this.$router.push('/')
        }
      }
      this.isFormLoading = false
  	}
  },
  setup () {
    const { pushNotification } = useNotifications()
    return { pushNotification }
  }
}
</script>

<style scoped lang="scss">
.signup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  .title {
    @extend %font-medium-sm;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    width: 100%;
  }

  .link {
    @extend %font-sb-sm;

    color: $color-primary-05;
    cursor: pointer;
  }

  .login-wrapper {
    @extend %font-medium-sm;

    display: flex;
    justify-content: center;
    gap: $spacing-4;
  }
}
</style>
